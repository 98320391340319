import closeIcon from '@/assets/close(mobile).png';
import discordLogo from '@/assets/discord.png';
import hamburgerIcon from '@/assets/hamburger(mobile).png';
import logo from '@/assets/logo.png';
import { GTMContext } from '@/context/GTMContext';
import useAccountStore from '@/store/zustand/useAccountStore';
import useModalStore from '@/store/zustand/useModalStore';
import useWeb3AuthStore from '@/store/zustand/useWeb3AuthStore';
import {
  gtmEvent_discord,
  gtmEvent_go_to_deposit,
  gtmEvent_go_to_faq,
  gtmEvent_go_to_help,
  gtmEvent_go_to_home,
  gtmEvent_go_to_login,
  gtmEvent_go_to_transactions,
  gtmEvent_go_to_withdrawal,
  gtmEvent_logout,
} from '@/utils/GTMFunctions';
import { useMediaQuery } from '@/utils/hooks/useMediaQuery';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import sign_out from '../../assets/sign_out.png';
import Button from '../Button';
const LogoutModal = dynamic(() => import('../Modal/LogoutModal'), { ssr: false });
// Navbar component
const Navbar = (props) => {
  // destructuring props

  // context hooks
  const { setDataLayer } = useContext(GTMContext);
  const isTablet = useMediaQuery('(max-width: 1200px)');
  const loggedIn = useAccountStore((state) => state.loggedIn);
  const logOut = useModalStore((state) => state.logOut);
  const setLogOut = useModalStore((state) => state.setLogOut);
  const email = useWeb3AuthStore((state) => state.email);
  const accountType = useAccountStore((state) => state.accountType);
  const walletAddress = useAccountStore((state) => state.walletAddress);
  const isAdmin = useAccountStore((state) => state.isAdmin);

  const router = useRouter();
  // state
  const [showMenu, setShowMenu] = useState(false);
  // effect

  // queries

  // other variables/functions/handlers

  const NavLinks = [
  ];

  useEffect(() => {
    const overallPage = document.getElementById('overAllPage');
    if (overallPage) {
      overallPage.style.overflow = showMenu ? 'hidden' : 'auto';
    }
  }, [showMenu]);

  // if show menu open and isn't tablet, close menu
  useLayoutEffect(() => {
    if (showMenu && !isTablet) {
      setShowMenu(false);
    }
  }, [isTablet]);

  const showWalletAddress = loggedIn && !showMenu && accountType === 'polygon';
  const showEmail = email && loggedIn && !showMenu && accountType !== 'polygon';
  const discordLink = 'https://discord.gg/wfRKwf7M';

  const handleDiscordClick = () => {
    setDataLayer(gtmEvent_discord());
    window.open(discordLink, '_blank');
    setShowMenu(false);
  };

  const handleMenuClick = (linkName) => {
    setShowMenu(false);
    switch (linkName) {
      case 'HOME':
        setDataLayer(gtmEvent_go_to_home());
        break;
      case 'DEPOSIT':
        setDataLayer(gtmEvent_go_to_deposit());
        break;
      case 'WITHDRAW':
        setDataLayer(gtmEvent_go_to_withdrawal());
        break;
      case 'HELP':
        setDataLayer(gtmEvent_go_to_help());
        break;
      case 'FAQ':
        setDataLayer(gtmEvent_go_to_faq());
        break;
      case 'LOGIN':
        setDataLayer(gtmEvent_go_to_login());
        break;
      case 'TRANSACTIONS':
        setDataLayer(gtmEvent_go_to_transactions());
        break;
      default:
        setDataLayer(gtmEvent_go_to_home());
        break;
    }
  };
  // render
  return (
    <div className={`navbar ${showMenu && 'navbar--extended'}`}>
      <div className="navbar__menu">
        {showMenu && <Image className="close" onClick={handleMenuClick} width={30} src={closeIcon} alt="sign out" />}
        <div className="navbar__menu-item">
          {!showMenu && (
            <div className="navbar__menu-item_logo">
              <Link href="/">
                <Image onClick={() => handleMenuClick('HOME')} width={150} src={logo} alt="logo" />
              </Link>
            </div>
          )}
          {!(isTablet && !showMenu) && (
            <div className="navbar__menu-item_links">
              {NavLinks.map((link, index) =>
                link.name === 'NOTIFICATIONS' ? null : (
                  <Link
                    className={`${router.pathname === link.path ? 'active' : showMenu ? 'responsive' : ``}`}
                    onClick={() => handleMenuClick(link.name)}
                    href={link.path}
                    key={index}
                  >
                    {link.name}
                  </Link>
                )
              )}
            </div>
          )}
          {showWalletAddress && (
            <div className="navbar__menu-address">
              <div className="navbar__menu-address">
                <div className="navbar__menu-item_address-address">{walletAddress}</div>
              </div>
              <div className="navbar__menu-address-subtitle">Wallet Address</div>
            </div>
          )}
          {showEmail && (
            <div className="navbar__menu-address">
              <div className="navbar__menu-address">
                <div className="navbar__menu-item_address-address">{email}</div>
              </div>
              <div className="navbar__menu-address-subtitle">Email Address</div>
            </div>
          )}
        </div>
      </div>
      {logOut && <LogoutModal isOpen={logOut} onClose={() => setLogOut(false)} />}
    </div>
  );
};

// export
export default Navbar;
