import Image from 'next/image';
import apple from '../../assets/apple.png';
import google from '../../assets/google.png';
import facebook from '../../assets/facebook.png';

// Button component
const Button = ({ prepIcon, variant = '', size = '', className = '', ...rest }) => {
  // destructuring props

  // context hooks

  // state

  // effect

  // queries

  // other variables/functions/handlers
  const prepIconReturn = () => {
    switch (prepIcon) {
      case 'apple':
        return apple;
      case 'google':
        return google;
      case 'facebook':
        return facebook;
      default:
        return null;
    }
  };
  // render
  return (
    <button
      className={prepIcon ? `${prepIcon}` : variant ? `button__outerContainer-${variant}` : 'button__outerContainer'}
      {...rest}
    >
      {prepIcon ? (
        <div className="text">
          <Image src={prepIconReturn()} alt="icon" width={20} height={20} />
          {rest.children}
        </div>
      ) : (
        <div className={variant ? `${variant}` : 'button__innerContainer'}>
          <div className="button__textContainer">{rest.children}</div>
        </div>
      )}
    </button>
  );
};

// export
export default Button;
